import React from 'react'
import YouTube from 'react-youtube'

import styles from './YoutubeVideo.module.scss'

const opts = {
  height: '560',
  width: '315',
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 0,
    controls: 0,
    showinfo: 0,
    allowfullscreen: 1,
  },
}

const YoutubeVideo = ({ videoId, ...props }: Props) => {
  return (
    <div {...props}>
      <div className={styles.VideoWrapper}>
        <YouTube videoId={videoId} opts={opts} />
      </div>
    </div>
  )
}

interface Props {
  videoId: string
  className?: string
}

export default YoutubeVideo
